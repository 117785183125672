import { useState, useEffect } from 'react';

const TimeSinceDate = ({ targetDate }) => {
  const [elapsedTime, setElapsedTime] = useState('');

  useEffect(() => {
    const calculateElapsedTime = () => {
      const now = new Date();
      const target = new Date(targetDate);
      const difference = now - target;

      const seconds = Math.floor((difference / 1000) % 60);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);

      setElapsedTime(`${hours}h ${minutes}m ${seconds}s`);
    };

    calculateElapsedTime();

    const intervalId = setInterval(calculateElapsedTime, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]); 

  return (
    <p id="time" className='text-4xl text-green-500 sm:text-8xl'>
      {elapsedTime}        
    </p>
  );
};

export default TimeSinceDate;
