import { Button } from "../ui/button";
import { Heading } from "../ui/heading";
import { Field } from '../ui/fieldset'
import { Input } from '../ui/input'
import { StackedLayout } from '../ui/stacked-layout'
import { useState } from "react";

export function Manual_Layout() {
  let [student_id, set_student_id] = useState();
  let params = new URLSearchParams(window.location.search);
  let school = params.get("school");

  return (
    <StackedLayout>
      <div class="mt-20 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-3xl">
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading>
              <Field>
                <Input name="student_id" placeholder="Student ID" value={student_id} onChange={(e) => set_student_id(e.target.value)} />
              </Field>
            </Heading>
            <div className="flex gap-4">
              <Button href="/scanner">Switch to Scanner</Button>
              <Button 
                color="green"
                onClick={() => window.location.href = `/ext?school=${school}&student=${student_id}`}
              >
                Start Pass
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
}