import { Avatar } from '../ui/avatar'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { Heading } from '../ui/heading'
import { Button } from '../ui/button'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../ui/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../ui/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../ui/sidebar'
import { SidebarLayout } from '../ui/sidebar-layout'
import { Textarea } from '../ui/textarea'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../ui/dialog'
import {
  CustomPaginationPrevious,
  Pagination,
} from '../ui/pagination'
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../ui/description-list'
import { Field, Label } from '../ui/fieldset'
import { Input } from '../ui/input'
import { Checkbox, CheckboxField, CheckboxGroup } from '../ui/checkbox'
import { Description, Fieldset, Legend } from '../ui/fieldset'
import { Text } from '../ui/text'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

function get_value_by_id(id, values) {
  const result = values.find(item => item.id == id);
  return result ? result.name : "";
}

export function Student_Page() {
  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem('hallpass_user'));
  let profile_picture = localStorage.getItem('hallpass_user_image');
  let [isOpen, setIsOpen] = useState(false);
  let [isStudentOpen, setIsStudentOpen] = useState(false);
  let [school, set_school] = useState([]);
  let [student, set_student] = useState([]);
  let [rewards, set_rewards] = useState([]);
  let [values, set_values] = useState([]);
  const student_id = window.location.href.split('/students/')[1];

  let [student_first_name, set_student_first_name] = useState();
  let [student_last_name, set_student_last_name] = useState();
  let [student_grade, set_student_grade] = useState();
  let [student_sem, set_student_sem] = useState();
  let [student_day, set_student_day] = useState();
  let [student_identifier, set_student_identifier] = useState();

  // reward selection
  let [reward_selection, set_reward_selection] = useState([]);
  let [reward_comment, set_reward_comment] = useState();

  let admin = user.staff[0].role == 'Admin';
  let role = user.staff[0].role;
  let clerk = user.staff[0].clerk;

  if(role == 'Student') {
    window.location.href = '/forbidden';
  }

  const handleChange = (id) => {
    set_reward_selection((prev) => prev.includes(id) ? prev.filter((value) => value !== id) : [...prev, id]);
  }

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/students/${student_id}`).then(response => response.json()).then(json => {
      set_school(json.school);
      set_student(json);

      set_student_first_name(json.student.firstName);
      set_student_last_name(json.student.lastName);
      set_student_grade(json.student.grade);
      set_student_sem(json.student.sem);
      set_student_day(json.student.day);
      set_student_identifier(json.student.identifier);
    }).catch(err => console.error(err));
  }, []);

  useEffect(() => {
    fetch(`https://api.hallpass.gg/students/${student_id}/rewards`).then(response => response.json()).then(json => {
      set_rewards(json.student);
    }).catch(err => console.error(err));
  }, []);

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/values`).then(response => response.json()).then(json => {
      set_values(json.values);
    }).catch(err => console.error(err));
  }, []);

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={profile_picture} className="size-10" square alt="" />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={school ? school.name : "..."} className="bg-zinc-900 text-white dark:bg-white dark:text-black" />
                <SidebarLabel>{school ? school.name : "..."}</SidebarLabel>
                {admin ? <ChevronDownIcon /> : ""}
              </DropdownButton>
              {admin ? (
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              ): ""}
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/">
                <HomeIcon />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/students">
                <Square2StackIcon />
                <SidebarLabel>Students</SidebarLabel>
              </SidebarItem>
              {clerk ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : admin ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : ""}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={profile_picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user.email.split('@')[0]}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <Pagination>
        <CustomPaginationPrevious onClick={() => navigate(-1)}  />
      </Pagination>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <h1 className='text-2xl/8 font-semibold text-zinc-950 sm:text-4xl/8 dark:text-white'>
          {student.student ? student.student.firstName + " " + student.student.lastName : ""}
        </h1>
        <div className="flex gap-4">
          {admin ? <Button onClick={() => setIsStudentOpen(true)} outline>Edit</Button> : ""}
          <Dialog open={isStudentOpen} onClose={setIsStudentOpen}>
            <DialogTitle>Kevin Muscara</DialogTitle>
            <DialogBody>
              <Field>
                <Label>First Name</Label>
                <Input name="full_name" placeholder="36" value={student_first_name} onChange={(e) => set_student_first_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Last Name</Label>
                <Input name="full_name" placeholder="36" value={student_last_name} onChange={(e) => set_student_last_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Grade</Label>
                <Input name="full_name" placeholder="36" value={student_grade} onChange={(e) => set_student_grade(e.target.value)} />
              </Field>
              <Field>
                <Label>Semester Pass Limit</Label>
                <Input name="full_name" placeholder="36" value={student_sem} onChange={(e) => set_student_sem(e.target.value)} />
              </Field>
              <Field>
                <Label>Daily Pass Limit</Label>
                <Input name="full_name" placeholder="2" value={student_day} onChange={(e) => set_student_day(e.target.value)} />
              </Field>
              <Field>
                <Label>Identifier</Label>
                <Input name="full_name" placeholder="1234" value={student_identifier} onChange={(e) => set_student_identifier(e.target.value)} />
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsStudentOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/students/${student.student ? student.student.id : undefined}/update`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                    firstName: student_first_name,
                    lastName: student_last_name,
                    grade: student_grade,
                    day: student_day,
                    sem: student_sem,
                    identifier: student_identifier
                  })}).then(response => response.json()).then((json) => {
                    if(json.status == 200) {
                      window.location.href = student.student ? `/students/${student.student.id}` : `/settings`;
                    }
                  });
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <DescriptionList>
        <DescriptionTerm>Daily</DescriptionTerm>
        <DescriptionDetails>{student.daily ? student.daily.out : "0"} / {student.student ? student.student.day : "0"}</DescriptionDetails>

        <DescriptionTerm>Semester</DescriptionTerm>
        <DescriptionDetails>{student.semester ? student.semester.out : "0"} / {student.student ? student.student.sem : "0"}</DescriptionDetails>

        <DescriptionTerm>Yearly</DescriptionTerm>
        <DescriptionDetails>{student.year ? student.year.out : "0"} / {student.student ? student.student.sem * 2 : "0"}</DescriptionDetails>

        <DescriptionTerm>Colts Crypto</DescriptionTerm>
        <DescriptionDetails>{rewards.length}</DescriptionDetails>
      </DescriptionList>

      {/* Rewards */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Rewards</Heading>
        <div className="flex gap-4">
          <Button onClick={() => setIsOpen(true)} color="green">Add Reward</Button>
          <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle>Give student a reward</DialogTitle>
            <DialogBody>
              <Fieldset>
                <CheckboxGroup>
                  {values.map((value) => {
                    return (
                      <CheckboxField>
                        <Checkbox 
                          value={value.id}
                          onChange={() => handleChange(value.id)}
                          checked={reward_selection.includes(value.id)}
                        />
                        <Label>{value.name}</Label>
                        <Description>{value.description}</Description>
                      </CheckboxField>
                    )
                  })}
                </CheckboxGroup>
              </Fieldset>
              <Field>
                <Label>Comments</Label>
                <Textarea placeholder="Leave your comments here." value={reward_comment} onChange={(e) => set_reward_comment(e.target.value)} />
              </Field>

            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/create/reward`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    student: student.student.id,
                    selection: reward_selection.join(','),
                    comment: reward_comment,
                    staff: user.staff[0].id
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = window.location.href;
                    }
                  });
                }}  
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Characteristics</TableHeader>
            <TableHeader>Rewarded By</TableHeader>
            <TableHeader>Comments</TableHeader>
            <TableHeader>Active</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards ? rewards.map((reward) => {
            return (
              <TableRow key="1">
                <TableCell>
                  {new Date(reward.date).toLocaleString().split(',')[0]}
                </TableCell>
                <TableCell>
                  {reward.values.map((value, index) => {
                    if((index + 1) >= reward.values.length) {
                      return get_value_by_id(value.value, values);
                    } else {
                      return get_value_by_id(value.value, values) + ', ';
                    }
                  })}
                </TableCell>
                <TableCell>
                  {reward.staffs.users.email}
                </TableCell>
                <TableCell>
                  {reward.comment}
                </TableCell>
                <TableCell>
                  {reward.active ? "Yes" : "No"}
                </TableCell>
                <TableCell>
                  <Button
                    color="red"
                    onClick={() => {
                      fetch(`https://api.hallpass.gg/delete/rewards/${reward.id}`).then(response => response.json()).then(json => {
                        if(json.status == 200) {
                          window.location.href = `/students/${reward.student}`;
                        }
                      }).catch(err => console.error(err));
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            )
          }) : ""}
        </TableBody>
      </Table>

      {/* Passes */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Passes</Heading>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Time Out</TableHeader>
            <TableHeader>Time In</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {student.passes ? student.passes.map((pass) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">{new Date(pass.createdAt).toLocaleString().split(',')[0]}</TableCell>
                <TableCell>{new Date(pass.createdAt).toLocaleTimeString()}</TableCell>
                <TableCell>{new Date(pass.updatedAt).toLocaleTimeString() == new Date(null).toLocaleTimeString() ? "--:--" : new Date(pass.updatedAt).toLocaleTimeString()}</TableCell>
                <TableCell>
                  <Button
                    color="red"
                    onClick={() => {
                      fetch(`https://api.hallpass.gg/delete/logs/${pass.id}`).then(response => response.json()).then(json => {
                        if(json.status == 200) {
                          window.location.href = window.location.href;
                        }
                      });
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            )
          }) : ""}
        </TableBody>
      </Table>
    </SidebarLayout>
  )
}