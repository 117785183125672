import { useEffect, useState } from "react";

export function Extension_Page() {
  let params = new URLSearchParams(window.location.search);
  let school = params.get("school");
  let student = params.get("student");

  let [response_status, set_response_status] = useState();
  let [response_error, set_response_error] = useState();
  let [response_redirect, set_response_redirect] = useState();

  useEffect(() => {
    fetch(`https://api.hallpass.gg/ext/${student}?school=${school}`).then(response => response.json()).then(json => {
      set_response_status(json.status);

      if(json.error !== undefined) {
        set_response_error(json.error);
      }

      if(json.redirect !== undefined) {
        set_response_redirect(json.redirect);
      }
    }).catch(err => console.error(err)); 
  }, []);

  if(response_status == 202) {
    return (
      <div class="flex min-h-full flex-col bg-white pb-12 pt-16">
        <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
          <div class="py-16">
            <div class="text-center">
              <p class="text-base font-semibold text-red-600">Error</p>
              <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unknown Student</h1>
              <p class="mt-2 text-base text-gray-500">Sorry, we couldn’t find the student you’re looking for.</p>
              <div class="mt-6">
                <a href={"/scan?school=" + school} class="text-base font-medium text-red-600 hover:text-red-500">
                  Try again
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }

  if(response_status == 205) {
    window.location.href = response_redirect;
  }

  if(response_status == 403) {
    return (
      <div class="flex min-h-full flex-col bg-white pb-12 pt-16">
        <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
          <div class="py-16">
            <div class="text-center">
              <p class="text-base font-semibold text-red-600">Error</p>
              <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">You Shall Not Pass!</h1>
              <p class="mt-2 text-base text-gray-500">{response_error}</p>
            </div>
          </div>
        </main>
      </div>
    )
  }
}