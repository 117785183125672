import { GoogleLogin } from "@react-oauth/google";
import { Button } from "../ui/button";
import { Field, Label } from "../ui/fieldset";
import { Heading } from "../ui/heading";
import { Input } from "../ui/input";
import { StackedLayout } from "../ui/stacked-layout";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";

export default function LoginPage2() {
  let [student_id, set_student_id] = useState();

  return (
    <StackedLayout>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-3xl">
          {/* grid */}
          <div className="grid grid-cols-2 gap-4">
            {/* student */}
            <div>
              <div class="overflow-hidden rounded-lg dark:bg-neutral-800">
                <div class="px-4 py-5 sm:p-6">
                  <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <Heading>Student Login</Heading>
                  </div>
                  <Field>
                    <Label>Student ID</Label>
                    <Input value={student_id} onChange={(e) => set_student_id(e.target.value)} />
                  </Field>
                  <div className="pt-2">
                    <Field>
                      <Button
                        onClick={() => {
                          window.location.href = `/preview?id=${student_id}`;
                        }}
                      >
                        Login
                      </Button>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            {/* staff */}
            <div>
              <div class="overflow-hidden rounded-lg dark:bg-neutral-800 pb-20">
                <div class="px-4 py-5 sm:p-6">
                  <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <Heading>Staff Login</Heading>
                  </div>
                  <GoogleLogin
                    onSuccess={async(credentialResponse) => {
                      const decode = jwtDecode(credentialResponse.credential);
                      const email = decode.email;
                      const picture = decode.picture;
              
                      // set profile
                      fetch(`https://api.hallpass.gg/users?email=${email}`).then(response => response.json()).then((json) => {
                        if(json.status == 200) {
                          localStorage.setItem('hallpass_user', JSON.stringify(json.user));
                          localStorage.setItem('hallpass_user_image', picture);
                          window.location.href = '/';
                        } else {
                          window.location.href = '/unlinked';
                        }
                      });
                    }}
                    onError={(error) => console.log(`login failed`, error)}
                    useOneTap
                  />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </StackedLayout>
  )
}
