import { Avatar } from '../ui/avatar'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { Heading } from '../ui/heading'
import { Button } from '../ui/button'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../ui/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../ui/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../ui/sidebar'
import { SidebarLayout } from '../ui/sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../ui/dialog'
import { Listbox, ListboxLabel, ListboxOption } from '../ui/listbox'
import { Field, Label } from '../ui/fieldset'
import { Input } from '../ui/input'
import { useEffect, useState } from 'react'
import { RedeemSearch } from './RedeemSearch'

export function Shop_Layout() {
  let user = JSON.parse(localStorage.getItem('hallpass_user'));
  let profile_picture = localStorage.getItem('hallpass_user_image');
  let [isOpen, setIsOpen] = useState(false);
  let [isEditOpen, setIsEditOpen] = useState(false);
  let [isRedeemOpen, setIsRedeemOpen] = useState(false);
  let [shop, set_shop] = useState([]);
  let [school, set_school] = useState([]);
  let [students, set_students] = useState([]);

  //create item
  let [name, set_name] = useState();
  let [desc, set_desc] = useState();
  let [cost, set_cost] = useState();

  let [selected_student, set_selected_student] = useState();
  let [selected_item, set_selected_item] = useState();

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/shop`).then(response => response.json()).then(json => {
      set_shop(json.shop);
      set_school(json.school);
    }).catch(err => console.error(err));
  }, [])

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/students`).then(response => response.json()).then(json => {
      set_students(json.data);
    }).catch(err => console.error(err));
  }, []);

  let admin = user.staff[0].role == 'Admin';
  let student = user.staff[0].role == 'Student';
  let clerk = user.staff[0].clerk;

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={profile_picture} className="size-10" square alt="" />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={school ? school.name : "..."} className="bg-zinc-900 text-white dark:bg-white dark:text-black" />
                <SidebarLabel>{school ? school.name : "..."}</SidebarLabel>
                {admin ? <ChevronDownIcon /> : ""}
              </DropdownButton>
              {admin ? (
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              ): ""}
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {student ? "" : (
                <>
                  <SidebarItem href="/">
                    <HomeIcon />
                    <SidebarLabel>Dashboard</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href="/students">
                    <Square2StackIcon />
                    <SidebarLabel>Students</SidebarLabel>
                  </SidebarItem>
                </>
              )}
              {clerk ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : admin ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : ""}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={profile_picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user.email.split('@')[0]}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Shop</Heading>
        <div className="flex gap-4">
          <Button onClick={() => setIsRedeemOpen(true)}>Redeem</Button>
          {admin ? <Button type="button" onClick={() => setIsOpen(true)} color="green">Add Item</Button> : ""}
          <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle>Add Shop Item</DialogTitle>
            <DialogDescription>
              Add an item to your schools shop!
            </DialogDescription>
            <DialogBody>
              <Field>
                <Label>Item Name</Label>
                <Input name="name" placeholder="Candy" value={name} onChange={(e) => set_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Item Description</Label>
                <Input name="description" placeholder="Snickers bar" value={desc} onChange={(e) => set_desc(e.target.value)} />
              </Field>
              <Field>
                <Label>Item Cost</Label>
                <Input name="cost" placeholder="1" value={cost} onChange={(e) => set_cost(e.target.value)} />
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/create/shop/${user.schools[0].id}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    name,
                    description: desc,
                    cost
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = '/shop';
                    }
                  });
                }}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={isRedeemOpen} onClose={setIsRedeemOpen}>
            <DialogTitle>Redeem Shop Item</DialogTitle>
            <p id="redeem_error" className='text-red-500'></p>
            <DialogBody>
              <RedeemSearch
                students={students}
                selected={selected_student}
                set_selected={set_selected_student}
              />
              <Field>
                <Label>Shop Item</Label>
                <Listbox name="status" defaultValue="selection" onChange={(value) => set_selected_item(value)}>
                  <ListboxOption value="selection">
                    <ListboxLabel>Select item...</ListboxLabel>
                  </ListboxOption>
                  {shop.map((item) => {
                    return (
                      <ListboxOption value={item.id}>
                        <ListboxLabel>{item.name} ({item.cost})</ListboxLabel>
                      </ListboxOption>
                    )
                  })}
                </Listbox>
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsRedeemOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/rewards/use`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    student: selected_student,
                    item: selected_item
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 204) {
                      document.getElementById('redeem_error').innerHTML = json.error;
                    } else {
                      window.location.href = window.location.href;
                    }
                  });
                }}
              >
                Redeem
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Item</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Cost</TableHeader>
            <TableHeader>Action</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {shop.map((shop_item) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">{shop_item.name}</TableCell>
                <TableCell>{shop_item.description}</TableCell>
                <TableCell>{shop_item.cost}</TableCell>
                <TableCell>
                  <Button onClick={() => {
                    fetch(`https://api.hallpass.gg/delete/shop/${shop_item.id}`).then(response => response.json()).then(json => {
                      if(json.status == 200) {
                        window.location.href = '/shop';
                      }
                    });
                  }} color="red">Delete</Button>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </SidebarLayout>
  )
}