import { useEffect, useState } from "react"

export function API_Schools() {
  let [schools, set_schools] = useState();
  useEffect(() => {
    fetch(`https://api.hallpass.gg/api/schools`).then(response => response.json()).then(json => {
      set_schools(json);
    })
  }, []);

  return (
    <>
      {
        schools ? JSON.stringify(schools) : ""
      }
    </>
  )
}