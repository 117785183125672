import { Button } from '../ui/button'
import { Heading } from "../ui/heading"
import { StackedLayout } from '../ui/stacked-layout'

export function Scan_Layout() {
  let params = new URLSearchParams(window.location.search);
  let school = params.get("school");

  return (
    <StackedLayout>
      <div class="mt-40 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-3xl">
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading>Start a pass</Heading>
            <div className="flex gap-4">
              <Button outline href={"/scanner?school=" + school}>Scanner</Button>
              <Button href={"/manual?school=" + school}>Manual</Button>
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
}