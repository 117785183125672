import { Avatar } from '../ui/avatar'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { Heading } from '../ui/heading'
import { Button } from '../ui/button'
import { Divider } from "../ui/divider"
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../ui/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../ui/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../ui/sidebar'
import { SidebarLayout } from '../ui/sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../ui/dialog'
import { Field, Label } from '../ui/fieldset'
import { Input } from '../ui/input'
import { useEffect, useState } from 'react'

export function Dashboard_Layout() {
  let user = JSON.parse(localStorage.getItem('hallpass_user'));
  let profile_picture = localStorage.getItem('hallpass_user_image');
  let [isOpen, setIsOpen] = useState(false);
  let [active_passes, set_active_passes] = useState([]);
  let [freq_flyers, set_freq_flyers] = useState([]);
  let [school, set_school] = useState(null);

  let admin = user.staff[0].role == 'Admin';
  let role = user.staff[0].role;
  let clerk = user.staff[0].clerk;

  if(role == 'Student') {
    window.location.href = '/shop';
  }

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/passes`).then(response => response.json()).then(json => {
      set_active_passes(json.data);
      set_school(json.school);
    }).catch(err => console.error(err));
  }, [])

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/1/frequent`).then(response => response.json()).then(json => {
      set_freq_flyers(json.data);
    }).catch(err => console.error(err));
  }, []);

  const sign_student_in = (student_id, school_id) => {
    fetch(`https://api.hallpass.gg/schools/${school_id}/students/${student_id}/signIn`).then(response => response.json()).then(json => {
      if(json.status == 200) {
        window.location.href = '/';
      }
    }).catch(err => console.error(err));
  }

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={profile_picture} className="size-10" square alt="" />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={school ? school.name : "..."} className="bg-zinc-900 text-white dark:bg-white dark:text-black" />
                <SidebarLabel>{school ? school.name : "..."}</SidebarLabel>
                {admin ? <ChevronDownIcon /> : ""}
              </DropdownButton>
              {admin ? (
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              ): ""}
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/">
                <HomeIcon />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/students">
                <Square2StackIcon />
                <SidebarLabel>Students</SidebarLabel>
              </SidebarItem>
              {clerk ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : admin ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : ""}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={profile_picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user.email.split('@')[0]}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {/* active passes */}
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Active Passes</Heading>
        <div className="flex gap-4">
          <Button color="blue" href={`/scan?school=${school ? school.id : ""}`} target="_blank">Kiosk</Button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Student Name</TableHeader>
            <TableHeader>Grade</TableHeader>
            <TableHeader>Out Since</TableHeader>
            {admin ? <TableHeader>Action</TableHeader> : ""}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            active_passes.map((pass) => {
              return (
                <TableRow key="1">
                  <TableCell className="font-medium">
                    <a href={`/students/${pass.id}`}>
                      {pass.firstName} {pass.lastName}
                    </a>
                  </TableCell>
                  <TableCell>{pass.grade}</TableCell>
                  <TableCell>{new Date(pass.recentPass.time).toLocaleString()}</TableCell>
                  {
                    admin ? 
                    <TableCell>
                      <Button type="button" onClick={() => setIsOpen(true)} color="red">Sign In</Button>
                      <Dialog open={isOpen} onClose={setIsOpen}>
                        <DialogTitle>Confirm Action</DialogTitle>
                        <DialogDescription>Are you sure you want to end this students pass?</DialogDescription>
                        <DialogActions>
                          <Button color="red" onClick={() => setIsOpen(false)}>No</Button>
                          <Button color="green" onClick={() => sign_student_in(pass.recentPass.student, school.id)}>Yes</Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                    : ""
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>

      {/* frequent flyers */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Frequent Flyers</Heading>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Student Name</TableHeader>
            <TableHeader>Grade</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {freq_flyers.map((freq) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">
                  <a href={`/students/${freq.id}`}>
                    {freq.firstName} {freq.lastName}
                  </a>
                </TableCell>
                <TableCell>{freq.grade}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </SidebarLayout>
  )
}