export function EndPass() {
  let params = new URLSearchParams(window.location.search);

  return (
    <div class="flex min-h-full flex-col bg-white pb-12 pt-16">
      <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
        <div class="py-16">
          <div class="text-center">
            <p class="text-base font-semibold text-green-600">Success</p>
            <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Pass Ended</h1>
          </div>
        </div>
      </main>
    </div>
  )
}