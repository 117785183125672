import { useEffect, useState } from "react";
import { StackedLayout } from "../ui/stacked-layout";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../ui/description-list'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { Heading } from '../ui/heading'

function get_value_by_id(id, values) {
  const result = values.find(item => item.id == id);
  return result ? result.name : "";
}

export function Preview_Page() {
  let params = new URLSearchParams(window.location.search);
  let id = params.get("id");

  let [school, set_school] = useState();
  let [identifier, set_identifier] = useState();
  let [student_id, set_student_id] = useState();

  let [first_name, set_first_name] = useState();
  let [last_name, set_last_name] = useState(); 

  let [daily, set_daily] = useState();
  let [daily_limit, set_daily_limit] = useState();

  let [semester, set_semester] = useState();
  let [semester_limit, set_semester_limit] = useState();

  let [yearly, set_yearly] = useState();
  let [yearly_limit, set_yearly_limit] = useState();
  
  let [passes, set_passes] = useState();
  let [timer, set_timer] = useState();

  let [rewards, set_rewards] = useState([]);
  let [values, set_values] = useState([]);

  useEffect(() => {
    fetch(`https://api.hallpass.gg/preview/${id}`).then(response => response.json()).then(json => {
      set_first_name(json.data.student.firstName);
      set_last_name(json.data.student.lastName);

      set_daily(json.data.daily.out);
      set_daily_limit(json.data.student.day);

      set_semester(json.data.semester.out);
      set_semester_limit(json.data.student.sem);

      set_yearly(json.data.year.out);
      set_yearly_limit(json.data.student.sem * 2);

      set_passes(json.data.passes);

      set_school(json.data.student.school);
      set_identifier(json.data.student.identifier);
      set_student_id(json.data.student.id);

      set_rewards(json.data.rewards);
      set_values(json.data.values);

      set_timer(json.data.mostRecent.time);
    }).catch(err => console.error(err));
  }, []);

  return (
    <StackedLayout>
      {/* Information */}
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <h1 className='text-2xl/8 font-semibold text-zinc-950 sm:text-4xl/8 dark:text-white'>
          {first_name} {last_name}
        </h1>
      </div>
      <DescriptionList>
        <DescriptionTerm>Daily</DescriptionTerm>
        <DescriptionDetails>{daily} / {daily_limit}</DescriptionDetails>

        <DescriptionTerm>Semester</DescriptionTerm>
        <DescriptionDetails>{semester} / {semester_limit}</DescriptionDetails>

        <DescriptionTerm>Yearly</DescriptionTerm>
        <DescriptionDetails>{yearly} / {yearly_limit}</DescriptionDetails>

        <DescriptionTerm>Colts Crypto</DescriptionTerm>
        <DescriptionDetails>{rewards.length}</DescriptionDetails>
      </DescriptionList>

      {/* Rewards */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Rewards</Heading>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Characteristics</TableHeader>
            <TableHeader>Rewarded By</TableHeader>
            <TableHeader>Comments</TableHeader>
            <TableHeader>Active</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards ? rewards.map((reward) => {
            return (
              <TableRow key="1">
                <TableCell>
                  {new Date(reward.date).toLocaleString().split(',')[0]}
                </TableCell>
                <TableCell>
                  {reward.values.map((value, index) => {
                    if((index + 1) >= reward.values.length) {
                      return get_value_by_id(value.value, values);
                    } else {
                      return get_value_by_id(value.value, values) + ', ';
                    }
                  })}
                </TableCell>
                <TableCell>
                  {reward.staffs.users.email}
                </TableCell>
                <TableCell>
                  {reward.comment}
                </TableCell>
                <TableCell>
                  {reward.active ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            )
          }) : ""}
        </TableBody>
      </Table>

      {/* Passes */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>
          Passes
        </Heading>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Date</TableHeader>
            <TableHeader>Time Out</TableHeader>
            <TableHeader>Time In</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {passes ? passes.map((pass) => {
            return (
              <TableRow>
                <TableCell>{new Date(pass.createdAt).toLocaleString().split(',')[0]}</TableCell>
                <TableCell>{new Date(pass.createdAt).toLocaleTimeString()}</TableCell>
                <TableCell>{new Date(pass.updatedAt).toLocaleTimeString() == new Date(null).toLocaleTimeString() ? "--:--" : new Date(pass.updatedAt).toLocaleTimeString()}</TableCell>
              </TableRow>
            )
          }) : (<></>)}
        </TableBody>
      </Table>

    </StackedLayout>
  )
}