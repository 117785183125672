import { Button } from "../ui/button";

export function Unlinked() {
  return (
    <div class="flex min-h-full flex-col bg-white pb-12 pt-16">
      <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
        <div class="py-16">
          <div class="text-center">
            <p class="text-base font-semibold text-green-600">Sorry!</p>
            <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Your account is not currently linked to a school.</h1>
            <div className="pt-2">
              <Button color="green" href="/logout">
                Try again
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}