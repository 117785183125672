import { Button } from "../ui/button";
import { Heading } from "../ui/heading";
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"
import { StackedLayout } from '../ui/stacked-layout'

export function Scanner_Layout() {
  let params = new URLSearchParams(window.location.search);
  let school = params.get("school");

  return (
    <StackedLayout>
      <div class="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-3xl">
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading>Please present your student ID</Heading>
            <div className="flex gap-4">
              <Button href="/manual">Switch to Manual</Button>
            </div>
          </div>
          <div class="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-3xl">
              <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                <BarcodeScanner
                  options={{ formats: ['code_128'] }}
                  onCapture={(result) => window.location.href = `/ext?school=${school}&student=${result.rawValue}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
}