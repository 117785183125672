import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, json, RouterProvider } from "react-router-dom"
import { googleLogout, GoogleOAuthProvider } from "@react-oauth/google"

import { Dashboard_Layout } from './comps/Dashboard_Layout';
import { Student_Layout } from './comps/Student_Layout';
import { Shop_Layout } from './comps/Shop_Layout';
import { School_Settings } from './comps/School_Settings';
import { Student_Page } from './comps/Student_Page';
import { Scan_Layout } from './comps/Scan_Layout';
import { Scanner_Layout } from './comps/Scanner_Layout';
import { Manual_Layout } from './comps/Manual_Layout';
import { LoginPage } from './comps/LoginPage';

import { PrimeReactProvider } from 'primereact/api';
import { Extension_Page } from './comps/Extension_Page';
import { Pass_Page } from './comps/Pass_Page';
import { EndPass } from './comps/EndPass';
import { API_Schools } from './comps/API_Schools';
import { Unlinked } from './comps/Unlinked';
import LoginPage2 from './comps/LoginPage2';
import { Preview_Page } from './comps/Preview_Page';

const router = createBrowserRouter([
  {
    path: '/settings',
    loader: () => {
      if(localStorage.getItem('hallpass_user') == undefined) {
        window.location.href = '/login';
      } else {
        return null;
      }
    },
    element: <School_Settings />
  },
  {
    path: '/forbidden',
    element: <>403 - Forbidden access.</>
  },
  {
    path: '/unlinked',
    element: <Unlinked />
  },
  {
    path: '/api/schools',
    element: <API_Schools />
  },
  {
    path: '/pass',
    element: <Pass_Page />
  },
  {
    path: '/end_pass',
    element: <EndPass />
  },
  {
    path: '/ext',
    element: <Extension_Page />
  },
  {
    path: '/',
    loader: () => {
      if(localStorage.getItem('hallpass_user') == undefined) {
        window.location.href = '/login';
      } else {
        return null;
      }
    },
    element: <Dashboard_Layout />
  },
  {
    path: '/students/:id',
    loader: () => {
      if(localStorage.getItem('hallpass_user') == undefined) {
        window.location.href = '/login';
      } else {
        return null;
      }
    },
    element: <Student_Page />
  },
  {
    path: '/preview',
    element: <Preview_Page />
  },
  {
    path: '/students',
    loader: () => {
      if(localStorage.getItem('hallpass_user') == undefined) {
        window.location.href = '/login';
      } else {
        return null;
      }
    },
    element: <Student_Layout />
  },
  {
    path: '/shop',
    loader: () => {
      if(localStorage.getItem('hallpass_user') == undefined) {
        window.location.href = '/login';
      } else {
        return null;
      }
    },
    element: <Shop_Layout />
  },
  // options page
  {
    path: '/scan',
    element: <Scan_Layout />
  },
  // camera scanner
  {
    path: '/scanner',
    element: <Scanner_Layout />
  },
  // manual scanner
  {
    path: '/manual',
    element: <Manual_Layout />
  },
  {
    path: '/login',
    loader: () => {
      if(localStorage.getItem("hallpass_user")) {
        window.location.href = '/';
      } else {
        return null;
      }
    },
    // element: <LoginPage />
    element: <LoginPage2 />
  },
  {
    path: '/logout',
    loader: () => {
      localStorage.removeItem('hallpass_user');
      window.location.href = '/login';
    },
    element: <></>
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <PrimeReactProvider value={{ unstyled: false }}>
    <GoogleOAuthProvider clientId='918606363674-kv8ef8gvqnoh9rkdk2uto54ui68jeuhk.apps.googleusercontent.com'>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </GoogleOAuthProvider>
  </PrimeReactProvider>
)

reportWebVitals();
