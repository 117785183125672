import React, { useEffect, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Field } from '../ui/fieldset';
import { Input } from '../ui/input';

// import "primereact/resources/themes/viva-dark/theme.css";

export function Students_Table({ students }) {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [selected, set_selected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    useEffect(() => {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      const applyTheme = () => {
        if (darkModeMediaQuery.matches) {
          import('primereact/resources/themes/viva-dark/theme.css');
        } else {
          import('primereact/resources/themes/viva-light/theme.css');
        }
      };

      applyTheme();

      darkModeMediaQuery.addEventListener('change', applyTheme);

      return () => {
        darkModeMediaQuery.removeEventListener('change', applyTheme);
      };
  }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
      return (
        <div className="flex justify-content-end">
          <Field>
            <Input placeholder="Search Students" value={globalFilterValue} onChange={onGlobalFilterChange} />
          </Field>
        </div>
      );
    };
    const header = renderHeader();

    const onRowSelect = (event) => {
      window.location.href = `/students/${event.data.id}`;
    }

    return (
      <DataTable value={students} removableSort scrollable scrollHeight='400px' stripedRows paginator rows={25} 
        filters={filters}
        selectionMode='single'
        selection={selected}
        onSelectionChange={(e) => set_selected(e.value)}
        onRowSelect={onRowSelect}
        filterDisplay="row" 
        loading={loading}
        globalFilterFields={['firstName', 'lastName', 'grade', 'out_count', 'in_count']} 
        header={header} 
        emptyMessage="No students found."
        pt={{
          root: {
            className: 'mt-5 min-w-full text-left text-sm/6 text-zinc-950 dark:text-white'
          },
          table: {
            className: 'min-w-full text-left text-sm/6 text-zinc-950 dark:text-white'
          },
          thead: {
            className: 'text-zinc-500 dark:text-zinc-400'
          },
          th: {
            className: `border-b border-b-zinc-950/10 px-4 py-2 font-medium first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-b-white/10 sm:first:pl-1 sm:last:pr-1`
          },
          td: {
            className: `font-medium relative px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] border-b border-zinc-950/5 dark:border-white/5 py-4 sm:first:pl-1 sm:last:pr-1`
          }
        }}
      >        
        <Column field='firstName' header='First Name' sortable></Column>
        <Column field='lastName' header='Last Name' sortable></Column>
        <Column field='grade' header='Grade' sortable></Column>
        <Column field='out_count' header='Check Outs' sortable></Column>
        <Column field='in_count' header='Check Ins' sortable></Column>
      </DataTable>
    );
}
        