import { Avatar } from '../ui/avatar'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '../ui/description-list'
import { Description, Fieldset, Legend } from '../ui/fieldset'
import { Checkbox, CheckboxField, CheckboxGroup } from '../ui/checkbox'
import { Text } from '../ui/text'
import { Heading } from '../ui/heading'
import { Button } from '../ui/button'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../ui/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../ui/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../ui/sidebar'
import { SidebarLayout } from '../ui/sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../ui/dialog'
import { Field, Label } from '../ui/fieldset'
import { Listbox, ListboxLabel, ListboxOption } from '../ui/listbox'
import { Input } from '../ui/input'
import { useEffect, useState } from 'react'

export function School_Settings() {
  let user = JSON.parse(localStorage.getItem('hallpass_user'));
  let profile_picture = localStorage.getItem('hallpass_user_image');
  let [isOpen, setIsOpen] = useState(false);
  let [isStaffOpen, setIsStaffOpen] = useState(false);
  let [isBlockOpen, setIsBlockOpen] = useState(false);
  let [isRewardOpen, setIsRewardOpen] = useState(false);

  // pbis dialog
  let [isPBOpen, setIsPBOpen] = useState([]);

  let [school, set_school] = useState([]);
  let [blocks, set_blocks] = useState([]);
  let [staff, set_staff] = useState([]);
  let [values, set_value] = useState([]);

  // school settings
  let [school_name, set_school_name] = useState();
  let [school_day, set_school_day] = useState();
  let [school_sem, set_school_sem] = useState();

  // create block
  let [block_name, set_block_name] = useState();
  let [block_start, set_block_start] = useState();
  let [block_end, set_block_end] = useState();
  let [block_limit, set_block_limit] = useState();

  // create staff
  let [create_staff, set_create_staff] = useState();
  let [staff_role, set_staff_role] = useState("admin");
  let [staff_clerk, set_staff_clerk] = useState(true);

  // create pbis
  let [pbis_name, set_pbis_name] = useState();
  let [pbis_desc, set_pbis_desc] = useState();

  // editing pbis
  let [edit_pbis_name, set_edit_pbis_name] = useState();
  let [edit_pbis_desc, set_edit_pbis_desc] = useState();

  let admin = user.staff[0].role == 'Admin';
  let role = user.staff[0].role;
  let clerk = user.staff[0].clerk;

  if(role == 'Student') {
    window.location.href = '/forbidden';
  }

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/blocks`).then(response => response.json()).then(json => {
      set_school(json.school);
      set_school_name(json.school.name);
      set_school_day(json.school.day);
      set_school_sem(json.school.sem);
      set_blocks(json.blocks);
    }).catch(err => console.error(err));
  }, [])

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/staff`).then(response => response.json()).then(json => {
      set_staff(json.staff);
    }).catch(err => console.error(err));
  }, []);

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/values`).then(response => response.json()).then(json => {
      set_value(json.values);
      setIsPBOpen(Array(json.values.length).fill(false));
    }).catch(err => console.error(err));
  }, []);

  const toggleModal = (index) => {
    const updatedIsOpen = [...isPBOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsPBOpen(updatedIsOpen);
  }

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={profile_picture} className="size-10" square alt="" />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={school ? school.name : "..."} className="bg-zinc-900 text-white dark:bg-white dark:text-black" />
                <SidebarLabel>{school ? school.name : "..."}</SidebarLabel>
                {admin ? <ChevronDownIcon /> : ""}
              </DropdownButton>
              {admin ? (
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              ): ""}
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/">
                <HomeIcon />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/students">
                <Square2StackIcon />
                <SidebarLabel>Students</SidebarLabel>
              </SidebarItem>
              {clerk ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : admin ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : ""}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={profile_picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user.email.split('@')[0]}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {/* Settings */}
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>{school.name}</Heading>
        <div className="flex gap-4">
          {admin ? <Button type="button" onClick={() => setIsOpen(true)} outline>Edit</Button> : ""}
          <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle>School Settings</DialogTitle>
            <DialogDescription></DialogDescription>
            <DialogBody>
              <Field>
                <Label>School Name</Label>
                <Input name="name" value={school_name} onChange={(e) => set_school_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Semester Pass Limit</Label>
                <Input name="sem" value={school_sem} onChange={(e) => set_school_sem(e.target.value)} />
              </Field>
              <Field>
                <Label>Daily Pass Limit</Label>
                <Input name="day" value={school_day} onChange={(e) => set_school_day(e.target.value)} />
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green" 
                onClick={() => {
                  fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                    name: school_name,
                    dailyLimit: school_day,
                    semesterLimit: school_sem
                  })}).then(response => response.json()).then((json) => {
                    if(json.status == 200) {
                      setIsOpen(false);
                      window.location.href = '/settings';
                    }
                  });
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <DescriptionList>
        <DescriptionTerm>Semester Pass Limit</DescriptionTerm>
        <DescriptionDetails>{school.sem}</DescriptionDetails>

        <DescriptionTerm>Daily Pass Limit</DescriptionTerm>
        <DescriptionDetails>{school.day}</DescriptionDetails>

      </DescriptionList>

      {/* Blocks */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Blocks</Heading>
        <div className="flex gap-4">
          {admin ? <Button type="button" onClick={() => setIsBlockOpen(true)} color="green">Add Block</Button> : ""}
          <Dialog open={isBlockOpen} onClose={setIsBlockOpen}>
            <DialogTitle>Add Block</DialogTitle>
            <DialogBody>
              <Field>
                <Label>Block name</Label>
                <Input name="name" placeholder="1st Period" value={block_name} onChange={(e) => set_block_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Pass Limit</Label>
                <Input name="limit" placeholder="6" value={block_limit} onChange={(e) => set_block_limit(e.target.value)} />
              </Field>
              <Field className="mt-2">
                <Label>Start Time</Label>
                <input type="time" value={block_start} onChange={(e) => set_block_start(e.target.value)}></input>
              </Field>
              <Field className="mt-2">
                <Label>End Time</Label>
                <input type="time" value={block_end} onChange={(e) => set_block_end(e.target.value)}></input>
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsBlockOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/block`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                    name: block_name, 
                    startTime: block_start, 
                    endTime: block_end, 
                    limit: block_limit
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = '/settings';
                    }
                  });
                }}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Start Time</TableHeader>
            <TableHeader>End Time</TableHeader>
            <TableHeader>Pass Limit</TableHeader>
            {admin ? <TableHeader>Action</TableHeader> : ""}
          </TableRow>
        </TableHead>
        <TableBody>
          {blocks.map((block) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">{block.name}</TableCell>
                <TableCell>{block.startTime}</TableCell>
                <TableCell>{block.endTime}</TableCell>
                <TableCell>{block.limit}</TableCell>
                {admin ?
                <TableCell>
                  <Button
                    color="red"
                    onClick={() => {
                      fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/block/delete`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                        id: block.id
                      })}).then(response => response.json()).then(json => {
                        if(json.status == 200) {
                          window.location.href = '/settings';
                        }
                      });
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
                : ""}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      {/* PBIS */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>PBIS</Heading>
        <div className="flex gap-4">
          {admin ? <Button type="button" onClick={() => setIsRewardOpen(true)} color="green">Add Characteristic</Button> : ""}
          <Dialog open={isRewardOpen} onClose={setIsRewardOpen}>
            <DialogTitle>Add Characteristic</DialogTitle>
            <DialogBody>
              <Field>
                <Label>Characteristic Name</Label>
                <Input name="name" placeholder="Ownership" value={pbis_name} onChange={(e) => set_pbis_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Characteristic Description</Label>
                <Input name="description" placeholder="Student showed responsibility and took ownership." value={pbis_desc} onChange={(e) => set_pbis_desc(e.target.value)} />
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsRewardOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/pbis`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    name: pbis_name,
                    description: pbis_desc,
                    school: user.schools[0].id
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = window.location.href;
                    }
                  });
                }}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Characteristic</TableHeader>
            <TableHeader>Description</TableHeader>
            {admin ? <TableHeader>Action</TableHeader> : ""}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.map((value, index) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">{value.name}</TableCell>
                <TableCell>{value.description}</TableCell>
                {admin ?
                <TableCell>
                  <Button onClick={() => toggleModal(index)} outline>Edit</Button>
                  <Dialog open={isPBOpen[index]} onClose={() => toggleModal(index)}>
                    <DialogTitle>Edit Characteristic</DialogTitle>
                    <DialogBody>
                      <Field>
                        <Label>Characteristic Name</Label>
                        <Input name="name" placeholder={value.name} onChange={(e) => set_edit_pbis_name(e.target.value)} />
                      </Field>
                      <Field>
                        <Label>Characteristic Description</Label>
                        <Input name="description" placeholder={value.description} onChange={(e) => set_edit_pbis_desc(e.target.value)} />
                      </Field>
                    </DialogBody>
                    <DialogActions>
                      <Button plain onClick={() => toggleModal(index)}>
                        Cancel
                      </Button>
                      <Button color="green" onClick={() => {
                        fetch(`https://api.hallpass.gg/pbis/${value.id}`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                          name: edit_pbis_name,
                          description: edit_pbis_desc,
                          school: value.school
                        })}).then(response => response.json()).then(json => {
                          if(json.status == 200) {
                            window.location.href = window.location.href;
                          }
                        })
                      }}>Update</Button>
                    </DialogActions>
                  </Dialog>
                </TableCell>
                : ""}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      {/* Staff */}
      <div className="mt-10 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Staff</Heading>
        <div className="flex gap-4">
          {admin ? <Button type="button" onClick={() => setIsStaffOpen(true)} color="green">Add Staff</Button> : ""}
          <Dialog open={isStaffOpen} onClose={setIsStaffOpen}>
            <DialogTitle>Add Staff</DialogTitle>
            <DialogDescription>
              Add a new staff member to your school.
              <p className='text-red-400' id='staff_error'></p>
            </DialogDescription>
            <DialogBody>
              <Field>
                <Label>Email</Label>
                <Input name="email" placeholder="andy.bernard@dundermifflin.com" value={create_staff} onChange={(e) => set_create_staff(e.target.value)} />
              </Field>
              <Field>
                <Label>Role</Label>
                <Listbox name="status" defaultValue="admin" value={staff_role} onChange={(e) => set_staff_role(e)}>
                  <ListboxOption value="Admin">
                    <ListboxLabel>Admin</ListboxLabel>
                  </ListboxOption>
                  <ListboxOption value="Teacher">
                    <ListboxLabel>Teacher</ListboxLabel>
                  </ListboxOption>
                  <ListboxOption value="Student">
                    <ListboxLabel>Student</ListboxLabel>
                  </ListboxOption>
                </Listbox>
              </Field>
              <Field>
                <CheckboxField className="mt-5">
                  <Checkbox name="clerk" checked={staff_clerk} onChange={(e) => set_staff_clerk(e)} />
                  <Label>Shop Clerk</Label>
                  <Description>Allow this user to operate the shop.</Description>
                </CheckboxField>
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsStaffOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/staff`, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
                    email: create_staff,
                    role: staff_role,
                    clerk: staff_clerk
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = '/settings';
                    } else {
                      document.getElementById('staff_error').innerHTML = json.error;
                    }
                  });
                }}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <Table striped>
        <TableHead>
          <TableRow>
            <TableHeader>Email</TableHeader>
            <TableHeader>Role</TableHeader>
            {admin ? <TableHeader>Action</TableHeader> : ""}
          </TableRow>
        </TableHead>
        <TableBody>
          {staff.map((member) => {
            return (
              <TableRow key="1">
                <TableCell className="font-medium">{member.users.email}</TableCell>
                <TableCell>{member.role}</TableCell>
                {admin ?
                <TableCell>
                  <Button
                    color="red"
                    onClick={() => {
                      fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/staff/${member.users.email}/delete`).then(response => response.json()).then(json => {
                        if(json.status == 200) {
                          window.location.href = '/settings';
                        } else {
                          alert(json.error);
                        }
                      });
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
                : ""}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

    </SidebarLayout>
  )
}