import { Avatar } from '../ui/avatar'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { Heading } from '../ui/heading'
import { Button } from '../ui/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../ui/dialog'
import { Field, Label } from '../ui/fieldset'
import { Input } from '../ui/input'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../ui/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../ui/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../ui/sidebar'
import { SidebarLayout } from '../ui/sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react'
import { Students_Table } from './Students_Table'

export function Student_Layout() {
  let user = JSON.parse(localStorage.getItem('hallpass_user'));
  let profile_picture = localStorage.getItem('hallpass_user_image');
  let [isOpen, setIsOpen] = useState(false);
  let [students, set_students] = useState([]);
  let [school, set_school] = useState([]);

  // create student
  let [first_name, set_first_name] = useState();
  let [last_name, set_last_name] = useState();
  let [identifier, set_identifier] = useState();
  let [grade, set_grade] = useState();
  let [day, set_day] = useState();
  let [sem, set_sem] = useState();

  let admin = user.staff[0].role == 'Admin';
  let student = user.staff[0].role == 'Student';
  let role = user.staff[0].role;
  let clerk = user.staff[0].clerk;

  if(role == 'Student') {
    window.location.href = '/forbidden';
  }

  useEffect(() => {
    fetch(`https://api.hallpass.gg/schools/${user.schools[0].id}/students`).then(response => response.json()).then(json => {
      set_school(json.school);
      set_students(json.data);
    }).catch(err => console.error(err));
  }, [])

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={profile_picture} className="size-10" square alt="" />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={school ? school.name : "..."} className="bg-zinc-900 text-white dark:bg-white dark:text-black" />
                <SidebarLabel>{school ? school.name : "..."}</SidebarLabel>
                {admin ? <ChevronDownIcon /> : ""}
              </DropdownButton>
              {admin ? (
                <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              ): ""}
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {student ? "" : (
                <>
                  <SidebarItem href="/">
                    <HomeIcon />
                    <SidebarLabel>Dashboard</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href="/students">
                    <Square2StackIcon />
                    <SidebarLabel>Students</SidebarLabel>
                  </SidebarItem>
                </>
              )}
              {clerk ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : admin ? 
                <SidebarItem href="/shop">
                  <TicketIcon />
                  <SidebarLabel>Shop</SidebarLabel>
                </SidebarItem>
              : ""}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={profile_picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user.email.split('@')[0]}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Students</Heading>
        <div className="flex gap-4">
          {admin ? <Button type="button" onClick={() => setIsOpen(true)} color="green">Add Student</Button> : ""}
          <Dialog open={isOpen} onClose={setIsOpen}>
            <DialogTitle>Add Student</DialogTitle>
            <DialogDescription>
              <p className='text-red-400' id='error_student'></p>
              Students added manually may be affected by the SIS sync at midnight, proceed with caution.
            </DialogDescription>
            <DialogBody>
              <Field>
                <Label>First Name</Label>
                <Input name="firstName" placeholder="Michael" value={first_name} onChange={(e) => set_first_name(e.target.value) } />
              </Field>
              <Field>
                <Label>Last Name</Label>
                <Input name="lastName" placeholder="Scott" value={last_name} onChange={(e) => set_last_name(e.target.value)} />
              </Field>
              <Field>
                <Label>Identifier</Label>
                <Input name="identifier" placeholder="01234" value={identifier} onChange={(e) => set_identifier(e.target.value)} />
              </Field>
              <Field>
                <Label>Grade</Label>
                <Input name="grade" placeholder="12th" value={grade} onChange={(e) => set_grade(e.target.value)} />
              </Field>
              <Field>
                <Label>Daily Limit</Label>
                <Input name="day" placeholder="2" value={day} onChange={(e) => set_day(e.target.value)} />
              </Field>
              <Field>
                <Label>Semester Limit</Label>
                <Input name="sem" placeholder="36" value={sem} onChange={(e) => set_sem(e.target.value)} />
              </Field>
            </DialogBody>
            <DialogActions>
              <Button plain onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                color="green"
                onClick={() => {
                  fetch(`https://api.hallpass.gg/create/students/${user.schools[0].id}`, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                    firstName: first_name,
                    lastName: last_name,
                    identifier,
                    grade,
                    day: parseInt(day),
                    sem: parseInt(sem),
                    status: 'in',
                    school: user.schools[0].id
                  })}).then(response => response.json()).then(json => {
                    if(json.status == 200) {
                      window.location.href = '/students';
                    } else {
                      document.getElementById('error_student').innerHTML = json.error;
                    }
                  })
                }}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <Students_Table students={students} />
    </SidebarLayout>
  )
}