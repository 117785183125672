import { useState } from "react";
import { Field, Label } from "../ui/fieldset";
import { Input } from "../ui/input";
import { Listbox, ListboxOption } from "../ui/listbox";

export function RedeemSearch({ students, selected, set_selected }) {
  const [query, setQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value) {
      const filtered = students.filter((student) => {
        let {firstName, lastName} = student;
        let name = `${firstName} ${lastName}`;
        return name.toLowerCase().includes(value.toLowerCase())
      });
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleSelect = (value) => {
    set_selected(value);
    setQuery(value);
    setFilteredOptions([]);
  };

  return (
    <Field>
      <Label>Student</Label>
      <Input 
        value={query}
        onChange={handleSearchChange}
        placeholder="Search Student"
      />
      {filteredOptions.length > 0 && (
        <Listbox name="status" value={selected} onChange={handleSelect}>
          {filteredOptions.map((student, index) => (
            <ListboxOption key={index} value={student.id}>
              {student.firstName} {student.lastName}
            </ListboxOption>
          ))}
        </Listbox>
      )}
    </Field>
  )
}